import { Component, EventEmitter, ViewChild } from "@angular/core";
import { ModalComponent } from "src/app/shared/modal/modal.component";

@Component({
    selector: 'information-modal',
    templateUrl: './information-modal.component.html',
    standalone: true,
    imports: [ModalComponent]
})
export class InformationModalComponent {
    @ViewChild("modal") modal: ModalComponent;

    public close: EventEmitter<void> = new EventEmitter();

    public text: string;

    public async open(text: string): Promise<void> {
        this.text = text;
        this.modal.open();
    }

    public resetModal() {
        this.close.emit();
        this.modal.close();
    }
}